export default [
  {
    title: 'Dashboard',
    resource: 'Dashboard',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.79 23.24H6.21C3.47 23.24 1.25 21.01 1.25 18.27V10.86C1.25 9.49996 2.09 7.78996 3.17 6.94996L8.56 2.74996C10.18 1.48996 12.77 1.42996 14.45 2.60996L20.63 6.93996C21.82 7.76996 22.75 9.54996 22.75 11V18.28C22.75 21.01 20.53 23.24 17.79 23.24ZM9.48 3.92996L4.09 8.12996C3.38 8.68996 2.75 9.95996 2.75 10.86V18.27C2.75 20.18 4.3 21.74 6.21 21.74H17.79C19.7 21.74 21.25 20.19 21.25 18.28V11C21.25 10.04 20.56 8.70996 19.77 8.16996L13.59 3.83996C12.45 3.03996 10.57 3.07996 9.48 3.92996Z" fill="#222222"/>
    <path d="M13.5 19.25H10.5C8.43 19.25 6.75 17.57 6.75 15.5V12.5C6.75 10.43 8.43 8.75 10.5 8.75H13.5C15.57 8.75 17.25 10.43 17.25 12.5V15.5C17.25 17.57 15.57 19.25 13.5 19.25ZM10.5 10.25C9.26 10.25 8.25 11.26 8.25 12.5V15.5C8.25 16.74 9.26 17.75 10.5 17.75H13.5C14.74 17.75 15.75 16.74 15.75 15.5V12.5C15.75 11.26 14.74 10.25 13.5 10.25H10.5Z" fill="#222222"/>
    <path d="M12 19.25C11.59 19.25 11.25 18.91 11.25 18.5V9.5C11.25 9.09 11.59 8.75 12 8.75C12.41 8.75 12.75 9.09 12.75 9.5V18.5C12.75 18.91 12.41 19.25 12 19.25Z" fill="#222222"/>
    <path d="M16.5 14.75H7.5C7.09 14.75 6.75 14.41 6.75 14C6.75 13.59 7.09 13.25 7.5 13.25H16.5C16.91 13.25 17.25 13.59 17.25 14C17.25 14.41 16.91 14.75 16.5 14.75Z" fill="#222222"/>
    </svg>`,
    route: 'dashboard',
    action: 'manage',
  },
]

export default [
  {
    title: 'Outbound',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 3.5H21V7.5" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 9.5L21 3.5" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 21.5H3V17.5" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 15.5L3 21.5" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.02 2.70001C13.37 2.57001 12.69 2.5 12 2.5C6.48 2.5 2 6.98 2 12.5C2 13.19 2.07001 13.86 2.20001 14.5" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.97998 22.3C10.63 22.43 11.31 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 11.82 21.93 11.15 21.8 10.5" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    tagVariant: 'light-warning',
    resource: 'Outbound',
    children: [
      {
        title: 'Packing',
        route: 'packing',
        resource: 'Outbound',
        action: 'manage',
      },
      {
        title: 'Pengeluaran Barang',
        route: 'pengeluaran-barang',
        resource: 'Outbound',
        action: 'manage',
      },
    ],
  },
]

export default [
  {
    title: 'Monitoring Pickup',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.56 18.47H6.44C2.46 18.47 1.25 17.26 1.25 13.28V6.94C1.25 2.96 2.46 1.75 6.44 1.75H8C8.41 1.75 8.75 2.09 8.75 2.5C8.75 2.91 8.41 3.25 8 3.25H6.44C3.3 3.25 2.75 3.8 2.75 6.94V13.27C2.75 16.41 3.3 16.96 6.44 16.96H17.55C20.69 16.96 21.24 16.41 21.24 13.27V12.38C21.24 11.97 21.58 11.63 21.99 11.63C22.4 11.63 22.74 11.97 22.74 12.38V13.27C22.75 17.26 21.54 18.47 17.56 18.47Z" fill="#222222"/>
            <path d="M12 23.25C11.59 23.25 11.25 22.91 11.25 22.5V17.72C11.25 17.31 11.59 16.97 12 16.97C12.41 16.97 12.75 17.31 12.75 17.72V22.5C12.75 22.91 12.41 23.25 12 23.25Z" fill="#222222"/>
            <path d="M22 14.25H2C1.59 14.25 1.25 13.91 1.25 13.5C1.25 13.09 1.59 12.75 2 12.75H22C22.41 12.75 22.75 13.09 22.75 13.5C22.75 13.91 22.41 14.25 22 14.25Z" fill="#222222"/>
            <path d="M16.5 23.25H7.5C7.09 23.25 6.75 22.91 6.75 22.5C6.75 22.09 7.09 21.75 7.5 21.75H16.5C16.91 21.75 17.25 22.09 17.25 22.5C17.25 22.91 16.91 23.25 16.5 23.25Z" fill="#222222"/>
            <path d="M17.4168 7.64587H12.8335C12.6627 7.64587 12.521 7.50421 12.521 7.33337V4.00004C12.521 2.90837 13.4085 2.02087 14.5002 2.02087H18.2502C18.421 2.02087 18.5627 2.16254 18.5627 2.33337V6.50004C18.5627 7.13337 18.0502 7.64587 17.4168 7.64587ZM13.146 7.02087H17.4168C17.7043 7.02087 17.9377 6.78754 17.9377 6.50004V2.64587H14.5002C13.7543 2.64587 13.146 3.25421 13.146 4.00004V7.02087Z" fill="#222222"/>
            <path d="M19.9168 10.1459H19.5002C19.3293 10.1459 19.1877 10.0042 19.1877 9.83337C19.1877 9.54587 18.9543 9.31254 18.6668 9.31254C18.3793 9.31254 18.146 9.54587 18.146 9.83337C18.146 10.0042 18.0043 10.1459 17.8335 10.1459H16.1668C15.996 10.1459 15.8543 10.0042 15.8543 9.83337C15.8543 9.54587 15.621 9.31254 15.3335 9.31254C15.046 9.31254 14.8127 9.54587 14.8127 9.83337C14.8127 10.0042 14.671 10.1459 14.5002 10.1459H14.0835C13.221 10.1459 12.521 9.44587 12.521 8.58337V7.33337C12.521 7.16254 12.6627 7.02087 12.8335 7.02087H17.4168C17.7043 7.02087 17.9377 6.78754 17.9377 6.50004V3.58337C17.9377 3.41254 18.0793 3.27087 18.2502 3.27087H19.0168C19.4293 3.27087 19.8085 3.49171 20.0127 3.85005L20.7252 5.09588C20.7793 5.19171 20.7793 5.31254 20.7252 5.40838C20.671 5.50421 20.5668 5.56254 20.4543 5.56254H19.9168C19.8585 5.56254 19.8127 5.60837 19.8127 5.66671V6.91671C19.8127 6.97504 19.8585 7.02087 19.9168 7.02087H21.1668C21.3377 7.02087 21.4793 7.16254 21.4793 7.33337V8.58337C21.4793 9.44587 20.7793 10.1459 19.9168 10.1459ZM19.771 9.52087H19.9168C20.4335 9.52087 20.8543 9.10004 20.8543 8.58337V7.64587H19.9168C19.5168 7.64587 19.1877 7.31671 19.1877 6.91671V5.66671C19.1877 5.26671 19.5127 4.93754 19.9168 4.93754L19.471 4.15838C19.3793 3.99588 19.2043 3.89587 19.0168 3.89587H18.5627V6.50004C18.5627 7.13337 18.0502 7.64587 17.4168 7.64587H13.146V8.58337C13.146 9.10004 13.5668 9.52087 14.0835 9.52087H14.2293C14.3668 9.04171 14.8085 8.68754 15.3335 8.68754C15.8585 8.68754 16.3002 9.04171 16.4377 9.52087H17.5668C17.7043 9.04171 18.146 8.68754 18.671 8.68754C19.196 8.68754 19.6335 9.04171 19.771 9.52087Z" fill="#222222"/>
            <path d="M15.3333 10.9792C14.7 10.9792 14.1875 10.4667 14.1875 9.83333C14.1875 9.2 14.7 8.6875 15.3333 8.6875C15.9667 8.6875 16.4792 9.2 16.4792 9.83333C16.4792 10.4667 15.9667 10.9792 15.3333 10.9792ZM15.3333 9.3125C15.0458 9.3125 14.8125 9.54583 14.8125 9.83333C14.8125 10.1208 15.0458 10.3542 15.3333 10.3542C15.6208 10.3542 15.8542 10.1208 15.8542 9.83333C15.8542 9.54583 15.6208 9.3125 15.3333 9.3125Z" fill="#222222"/>
            <path d="M18.6668 10.9792C18.0335 10.9792 17.521 10.4667 17.521 9.83333C17.521 9.2 18.0335 8.6875 18.6668 8.6875C19.3002 8.6875 19.8127 9.2 19.8127 9.83333C19.8127 10.4667 19.3002 10.9792 18.6668 10.9792ZM18.6668 9.3125C18.3793 9.3125 18.146 9.54583 18.146 9.83333C18.146 10.1208 18.3793 10.3542 18.6668 10.3542C18.9543 10.3542 19.1877 10.1208 19.1877 9.83333C19.1877 9.54583 18.9543 9.3125 18.6668 9.3125Z" fill="#222222"/>
            <path d="M21.1667 7.64583H19.9167C19.5167 7.64583 19.1875 7.31667 19.1875 6.91667V5.66667C19.1875 5.26667 19.5167 4.9375 19.9167 4.9375H20.4542C20.5667 4.9375 20.6708 4.99584 20.725 5.09584L21.4375 6.34584C21.4625 6.39167 21.4792 6.44583 21.4792 6.5V7.33333C21.4792 7.50417 21.3375 7.64583 21.1667 7.64583ZM19.9167 5.5625C19.8583 5.5625 19.8125 5.60833 19.8125 5.66667V6.91667C19.8125 6.975 19.8583 7.02083 19.9167 7.02083H20.8542V6.58334L20.2708 5.5625H19.9167Z" fill="#222222"/>
            </svg>`,
    tagVariant: 'light-warning',
    route: 'monitoring-pickup',
    resource: 'MonitoringPickup',
    action: 'manage',
  },
]

export default [
  {
    title: 'Pengaturan',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    resource: 'Pin',
    children: [
      {
        title: 'Profile',
        route: 'profile',
        resource: 'Profile',
        action: 'manage',
      },
      {
        title: 'Rekening Bank',
        route: 'rekening-bank',
        resource: 'RekeningBank',
        action: 'manage',
      },
      {
        title: 'PIN',
        route: 'pin',
        resource: 'Pin',
        action: 'manage',
      },
    ],
  },
]

import dashboardKompack from './kompack/dashboard'
import inbound from './kompack/inbound'
import outbound from './kompack/outbound'
import gudang from './kompack/gudang'
import keuangan from './kompack/keuangan'
import setting from './kompack/setting'
import ticketing from './komship/ticketing'
import PackageReturn from './komship/PackageReturn'
import monitoringPickup from './komship/monitoring-pickup'
import dashboard from './komship/dashboard'
import overview from './komcards/overview'

// Array of sections
export default [
  ...dashboardKompack,
  ...inbound,
  ...outbound,
  ...gudang,
  ...keuangan,
  ...setting,
  ...dashboard,
  ...monitoringPickup,
  ...ticketing,
  ...PackageReturn,
  ...overview,
]

export default [
  {
    title: 'Gudang',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    resource: 'DataInbound',
    children: [
      {
        title: 'Info Gudangku',
        route: 'info-gudang',
        resource: 'DataInbound',
        action: 'manage',
      },
      {
        title: 'Partner & Stock',
        route: 'stok-gudang',
        resource: 'DataInbound',
        action: 'manage',
      },
    ],
  },
]

export default [
  {
    title: 'Keuangan',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 11.65H7" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 11.6501V7.03009C2 4.99009 3.65 3.34009 5.69 3.34009H11.31C13.35 3.34009 15 4.61009 15 6.65009" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.48 12.7C16.98 13.18 16.74 13.92 16.94 14.68C17.19 15.61 18.11 16.2 19.07 16.2H20V17.65C20 19.86 18.21 21.65 16 21.65H6C3.79 21.65 2 19.86 2 17.65V10.65C2 8.44002 3.79 6.65002 6 6.65002H16C18.2 6.65002 20 8.45002 20 10.65V12.1H18.92C18.36 12.1 17.85 12.32 17.48 12.7Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.0002 13.12V15.18C22.0002 15.74 21.5402 16.2 20.9702 16.2H19.0402C17.9602 16.2 16.9702 15.41 16.8802 14.33C16.8202 13.7 17.0602 13.11 17.4802 12.7C17.8502 12.32 18.3602 12.1 18.9202 12.1H20.9702C21.5402 12.1 22.0002 12.56 22.0002 13.12Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    tagVariant: 'light-warning',
    resource: 'DataInbound',
    route: 'finance',
    action: 'manage',
  },
]

export default [
  {
    title: 'Overview',
    customicon: '<span class="k-home-hashtag text-lg mr-[10px] "  />',
    tagVariant: 'light-warning',
    resource: 'OverviewMonitoring',
    route: 'overview-monitoring',
    action: 'manage',
  },
]

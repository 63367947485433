export default [
  {
    title: 'Monitoring Retur',
    resource: 'Mitra Ticketing',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 15.25H2C1.59 15.25 1.25 14.91 1.25 14.5V8.12C1.25 7.79999 1.44999 7.51997 1.73999 7.40997C2.03999 7.29997 2.37001 7.39001 2.57001 7.63C3.17001 8.34 4.03999 8.73999 4.98999 8.73999C5.01999 8.73999 5.04001 8.73999 5.07001 8.73999C5.87001 8.71999 6.60998 8.42001 7.16998 7.89001C7.42998 7.67001 7.63999 7.41 7.79999 7.12C8.10999 6.59 8.26 6.00998 8.25 5.41998C8.23 4.49998 7.83002 3.65999 7.15002 3.04999C6.91002 2.83999 6.83 2.50997 6.94 2.21997C7.05 1.92997 7.33001 1.72998 7.64001 1.72998H15C15.41 1.72998 15.75 2.06998 15.75 2.47998V12.48C15.75 14.02 14.52 15.25 13 15.25ZM2.75 13.75H13C13.69 13.75 14.25 13.19 14.25 12.5V3.25H9.19C9.54 3.9 9.73 4.63001 9.75 5.39001C9.77 6.26001 9.54998 7.12 9.10999 7.87C8.86999 8.29999 8.53999 8.71001 8.17999 9.01001C7.37999 9.77001 6.27999 10.22 5.10999 10.25C4.27999 10.28 3.46001 10.07 2.76001 9.69V13.75H2.75Z" fill="#222222"/>
      <path d="M19 21.25H18C17.59 21.25 17.25 20.91 17.25 20.5C17.25 19.81 16.69 19.25 16 19.25C15.31 19.25 14.75 19.81 14.75 20.5C14.75 20.91 14.41 21.25 14 21.25H10C9.59 21.25 9.25 20.91 9.25 20.5C9.25 19.81 8.69 19.25 8 19.25C7.31 19.25 6.75 19.81 6.75 20.5C6.75 20.91 6.41 21.25 6 21.25H5C2.93 21.25 1.25 19.57 1.25 17.5V14.5C1.25 14.09 1.59 13.75 2 13.75H13C13.69 13.75 14.25 13.19 14.25 12.5V5.5C14.25 5.09 14.59 4.75 15 4.75H16.84C17.83 4.75 18.74 5.28001 19.23 6.14001L20.94 9.13C21.07 9.36 21.07 9.65 20.94 9.88C20.81 10.11 20.56 10.25 20.29 10.25H19C18.86 10.25 18.75 10.36 18.75 10.5V13.5C18.75 13.64 18.86 13.75 19 13.75H22C22.41 13.75 22.75 14.09 22.75 14.5V17.5C22.75 19.57 21.07 21.25 19 21.25ZM18.65 19.75H19C20.24 19.75 21.25 18.74 21.25 17.5V15.25H19C18.04 15.25 17.25 14.46 17.25 13.5V10.5C17.25 9.54 18.03 8.75 19 8.75L17.93 6.88C17.71 6.49 17.29 6.25 16.84 6.25H15.75V12.5C15.75 14.02 14.52 15.25 13 15.25H2.75V17.5C2.75 18.74 3.76 19.75 5 19.75H5.34998C5.67998 18.6 6.74 17.75 8 17.75C9.26 17.75 10.32 18.6 10.65 19.75H13.36C13.69 18.6 14.75 17.75 16.01 17.75C17.27 17.75 18.32 18.6 18.65 19.75Z" fill="#222222"/>
      <path d="M8 23.25C6.48 23.25 5.25 22.02 5.25 20.5C5.25 18.98 6.48 17.75 8 17.75C9.52 17.75 10.75 18.98 10.75 20.5C10.75 22.02 9.52 23.25 8 23.25ZM8 19.25C7.31 19.25 6.75 19.81 6.75 20.5C6.75 21.19 7.31 21.75 8 21.75C8.69 21.75 9.25 21.19 9.25 20.5C9.25 19.81 8.69 19.25 8 19.25Z" fill="#222222"/>
      <path d="M16 23.25C14.48 23.25 13.25 22.02 13.25 20.5C13.25 18.98 14.48 17.75 16 17.75C17.52 17.75 18.75 18.98 18.75 20.5C18.75 22.02 17.52 23.25 16 23.25ZM16 19.25C15.31 19.25 14.75 19.81 14.75 20.5C14.75 21.19 15.31 21.75 16 21.75C16.69 21.75 17.25 21.19 17.25 20.5C17.25 19.81 16.69 19.25 16 19.25Z" fill="#222222"/>
      <path d="M22 15.25H19C18.04 15.25 17.25 14.46 17.25 13.5V10.5C17.25 9.54 18.04 8.75 19 8.75H20.29C20.56 8.75 20.81 8.89 20.94 9.13L22.65 12.13C22.71 12.24 22.75 12.37 22.75 12.5V14.5C22.75 14.91 22.41 15.25 22 15.25ZM19 10.25C18.86 10.25 18.75 10.36 18.75 10.5V13.5C18.75 13.64 18.86 13.75 19 13.75H21.25V12.7L19.85 10.25H19Z" fill="#222222"/>
      <path d="M5 10.25C3.34 10.25 1.78 9.37 0.940002 7.94C0.490002 7.22 0.25 6.37 0.25 5.5C0.25 4.04 0.900029 2.68998 2.03003 1.78998C2.87003 1.11998 3.93 0.75 5 0.75C7.62 0.75 9.75 2.88 9.75 5.5C9.75 6.37 9.51 7.22001 9.06 7.95001C8.81 8.37001 8.48999 8.75001 8.10999 9.07001C7.27999 9.83001 6.17 10.25 5 10.25ZM5 2.25C4.26 2.25 3.55997 2.49997 2.96997 2.96997C2.19997 3.57997 1.75 4.51 1.75 5.5C1.75 6.09 1.90997 6.66998 2.21997 7.16998C2.79997 8.14998 3.87 8.75 5 8.75C5.79 8.75 6.55 8.46 7.13 7.94C7.39001 7.72 7.61002 7.45999 7.77002 7.17999C8.09002 6.66999 8.25 6.09 8.25 5.5C8.25 3.71 6.79 2.25 5 2.25Z" fill="#222222"/>
      <path d="M6.06992 7.28995C5.87992 7.28995 5.68989 7.21998 5.53989 7.06998L3.4299 4.95999C3.1399 4.66999 3.1399 4.19 3.4299 3.9C3.7199 3.61 4.1999 3.61 4.4899 3.9L6.59989 6.00998C6.88989 6.29998 6.88989 6.77998 6.59989 7.06998C6.44989 7.21998 6.25992 7.28995 6.06992 7.28995Z" fill="#222222"/>
      <path d="M3.93009 7.31992C3.74009 7.31992 3.55012 7.24989 3.40012 7.09989C3.11012 6.80989 3.11012 6.32989 3.40012 6.03989L5.5101 3.9299C5.8001 3.6399 6.2801 3.6399 6.5701 3.9299C6.8601 4.2199 6.8601 4.6999 6.5701 4.9899L4.46011 7.09989C4.31011 7.24989 4.12009 7.31992 3.93009 7.31992Z" fill="#222222"/>
      </svg>`,
    route: 'package-return',
    action: 'manage',
  },
]

export default [
  {
    title: 'Kendala Pengiriman',
    resource: 'Mitra Ticketing',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 2.5V12.5C15 13.6 14.1 14.5 13 14.5H2V6.5C2 4.29 3.79 2.5 6 2.5H15Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 14.5V17.5C22 19.16 20.66 20.5 19 20.5H18C18 19.4 17.1 18.5 16 18.5C14.9 18.5 14 19.4 14 20.5H10C10 19.4 9.1 18.5 8 18.5C6.9 18.5 6 19.4 6 20.5H5C3.34 20.5 2 19.16 2 17.5V14.5H13C14.1 14.5 15 13.6 15 12.5V5.5H16.84C17.56 5.5 18.22 5.89001 18.58 6.51001L20.29 9.5H19C18.45 9.5 18 9.95 18 10.5V13.5C18 14.05 18.45 14.5 19 14.5H22Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 22.5C9.10457 22.5 10 21.6046 10 20.5C10 19.3954 9.10457 18.5 8 18.5C6.89543 18.5 6 19.3954 6 20.5C6 21.6046 6.89543 22.5 8 22.5Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 22.5C17.1046 22.5 18 21.6046 18 20.5C18 19.3954 17.1046 18.5 16 18.5C14.8954 18.5 14 19.3954 14 20.5C14 21.6046 14.8954 22.5 16 22.5Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 12.5V14.5H19C18.45 14.5 18 14.05 18 13.5V10.5C18 9.95 18.45 9.5 19 9.5H20.29L22 12.5Z" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3971 5.80188C5.71894 5.45591 6.22268 5.375 6.87209 5.375H10.1279C10.7773 5.375 11.2811 5.45591 11.6029 5.80188C11.9247 6.14786 12 6.68938 12 7.3875V7.7375C12 7.88247 11.8907 8 11.7558 8C11.4414 8 11.186 8.27447 11.186 8.6125C11.186 8.95053 11.4414 9.225 11.7558 9.225C11.8907 9.225 12 9.34253 12 9.4875C12 10.1856 11.9247 10.7271 11.6029 11.0731C11.2811 11.4191 10.7773 11.5 10.1279 11.5H6.87209C6.22268 11.5 5.71894 11.4191 5.3971 11.0731C5.07526 10.7271 5 10.1856 5 9.4875V9.3125C5 9.16753 5.10933 9.05 5.24419 9.05C5.55863 9.05 5.81395 8.77553 5.81395 8.4375C5.81395 8.09947 5.55863 7.825 5.24419 7.825C5.10933 7.825 5 7.70747 5 7.5625V7.3875C5 6.68938 5.07526 6.14786 5.3971 5.80188ZM5.48857 7.33052C5.95501 7.44918 6.30233 7.89994 6.30233 8.4375C6.30233 8.97506 5.95501 9.42582 5.48857 9.54448C5.49324 10.2076 5.58047 10.5278 5.74243 10.7019C5.90897 10.8809 6.21919 10.975 6.87209 10.975H10.1279C10.7808 10.975 11.091 10.8809 11.2576 10.7019C11.4047 10.5438 11.4901 10.2651 11.5081 9.71862C11.0433 9.59865 10.6977 9.14876 10.6977 8.6125C10.6977 8.07486 11.0451 7.62405 11.5116 7.50547V7.3875C11.5116 6.68562 11.4241 6.35214 11.2576 6.17312C11.091 5.99409 10.7808 5.9 10.1279 5.9H6.87209C6.21919 5.9 5.90897 5.99409 5.74243 6.17312C5.58047 6.34722 5.49324 6.66743 5.48857 7.33052Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0625 5.375C8.30412 5.375 8.5 5.49253 8.5 5.6375L8.5 6.3375C8.5 6.48247 8.30412 6.6 8.0625 6.6C7.82088 6.6 7.625 6.48247 7.625 6.3375L7.625 5.6375C7.625 5.49253 7.82088 5.375 8.0625 5.375ZM8.0625 7.475C8.30412 7.475 8.5 7.59253 8.5 7.7375L8.5 9.1375C8.5 9.28247 8.30412 9.4 8.0625 9.4C7.82088 9.4 7.625 9.28247 7.625 9.1375L7.625 7.7375C7.625 7.59253 7.82088 7.475 8.0625 7.475ZM8.0625 10.275C8.30412 10.275 8.5 10.3925 8.5 10.5375L8.5 11.2375C8.5 11.3825 8.30412 11.5 8.0625 11.5C7.82088 11.5 7.625 11.3825 7.625 11.2375L7.625 10.5375C7.625 10.3925 7.82088 10.275 8.0625 10.275Z" fill="#222222"/>
      </svg>`,
    route: 'issue-ticketing',
    action: 'manage',
  },
]
